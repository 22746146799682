import React, { useCallback, useState, useLayoutEffect, useRef, useEffect } from "react";

export default function Page({ id, title = "", bgStyle = "", children }) {
    return (
        <div className={"app-page " + bgStyle} id={id}>
            <h1 className="display-1 mt-5 mb-4 app-page-head">{title}</h1>
            <div className="mx-auto my-5 container-fluid d-flex flex-column align-items-center app-page-content">
                {children}
            </div>
        </div>
    );
}

