export default function Nav({ pageClickHandler = () => { }, items }) {
    return (
        <>
            <header className="sticky-top navbar app-nav-bar" >
                <div className="container-fluid d-flex flex-wrap align-items-center justify-content-center " id="topLevelNav">
                    <ul id="mainNav" className="nav mb-2 justify-content-center col-12 col-lg-auto me-lg-auto mb-md-0 mx-4 " >
                        <li className="nav-item">
                            <a onClick={pageClickHandler} className="nav-link px-8 text-light" href="#Main">
                                <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 12.5L12.5 3L23 12.5" stroke="white" strokeWidth="3" />
                                </svg>
                            </a>
                        </li>
                        {items.map(a =>
                            <li className="nav-item">
                                <a onClick={pageClickHandler} className="nav-link px-8 text-light" href={"#" + a.id}>
                                    {a.title}
                                </a>
                            </li>
                        )}
                    </ul>

                </div>
            </header>
        </>
    );
}

