import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Nav from './components/Nav';
import Section from './components/Section';
import Lipsum from './components/Lipsum';
import Page from './components/Page';
import Venue from './components/Venue';

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="text-center" data-bs-spy="scroll" data-bs-target="#topLevelNav" data-bs-smooth-scroll="true">

                    <div className="app-banner-container" id="Main">
                        <img className="app-banner-text" src="/images/banner_text.png" />
                    </div>

                    <Nav items={[
                        { id: "Story", title: "Story" },
                        { id: "TLDR", title: "Event" },
                        { id: "RSVP", title: "RSVP" },
                        { id: "Registry", title: "Registry" },
                        { id: "Travel", title: "Travel" },
                        { id: "Contact", title: "Contact" },
                    ]} />

                    <div className="app-banner-filler"></div>

                    <Page id="Story" title="Our Story" bgStyle="app-bg-grad-wedding-sage" >
                        <Section style={{ height: "800px" }} title="Dance with me tonight" img1="/images/c_duet.jpg" img2="/images/n_duet.jpg" type="left">
                            <p>
                                <b>Ash:</b> We met Sept. 30, 2016, during a swing-dance social at the 7th Street Public Market in uptown Charlotte, N.C. I learned
                                swing dancing in college, which I missed doing while living in South Carolina after graduating, and was so excited to pick
                                it up again when I moved to Charlotte in 2016. Meeting a guy wasn't on my mind. I seriously just wanted to tear
                                up the dance floor again.
                            </p>
                            <p>
                                <b>Sai:</b> You see, I never was good at dancing and was very conscious of dancing in public before I started attending swing
                                dance socials in Charlotte. They are a lot of fun and beginner friendly. This is also where I found my dancing self and learned
                                to let go on the dance floor. I had been going swing dancing regularly for a few years by this point and gotten pretty good at it.
                                It was at one of these socials that I saw Ashley.
                            </p>
                            <p>
                                <b>Ash:</b> During one of the first swing songs of the night, Sai asked me for a dance. (Cliche here about from that moment on
                                our lives being forever changed, etc.) Jokes aside, it was a great dance and we exchanged some friendly banter. I probably
                                talked about real estate too much. But that apparently didn't scare him away, as we danced a few more times, chatted throughout
                                the night and he joined me and some friends at Duckworth's afterwards for dinner.
                            </p>
                        </Section>

                        <Section style={{ height: "800px" }} title="Arts and outdoors" img1="/images/c_bike_rides.jpg" img2="/images/n_bike_rides.jpg" type="right">
                            <p>
                                <b>Ash:</b> On Oct. 16, 2016, we had our first date — but Sai wanted the details to be a surprise and didn't let on to what he had planned.
                                We ended up at Kings Mountain State Park (a mountain lite experience near Charlotte for those not in the know) for a short hike.
                                We learned dancing wasn't the only thing we both enjoyed doing — like most basic millennials, hikes, bike rides, arts and crafts,
                                traveling, "Game of Thrones," and checking out local hole-in-the-wall restaurants were some of our mutual interests.
                                The date lasted 12 hours.
                            </p>
                        </Section>

                        <Section style={{ height: "800px" }} title="We like to be goofy ..." img1="/images/n_goofy.jpg" img2="/images/n_goofy.jpg" type="left">
                            <p>
                                <b>Ash:</b> Our relationship has included adventures big and small: weekends in the North Carolina mountains, traveling across the Southeast,
                                Roman candle battles ("Yer a wizard, Ashley!"), biking Charlotte's greenways, that one time we climbed a waterfall, and navigating a
                                pandemic while working from and living in (and losing my mind in) a two-bedroom apartment. While we have very different personalities,
                                one of things I love most about our relationship is that we laugh at the same weird things. Sai somehow always knows how to make me
                                laugh, which is necessary when you're quarantining together indefinitely and spend 24/7 in each other's presence.
                            </p>
                        </Section>

                        <Section style={{ height: "800px" }} title="... and spice things up" img1="/images/c_spice_it_up.jpg" img2="/images/n_spice_it_up.jpg" type="right">
                            <p>
                                <b>Ash:</b> I will insist Sai didn't actually introduce me to Indian food, but he certainly expanded my palate and appreciation for the
                                cuisine, especially as a vegetarian. We cooked an Indian dish together on our second date (chole, which makes a frequent
                                appearance in our household dinner rotation) and, from then on, I was sold. Cooking together is one of our favorite things to do as a couple, followed
                                by endless scrolling on Netflix to pick something to watch (usually a horror movie if I'm deciding, Tollywood epic if Sai is).
                            </p>
                        </Section>

                        <Section style={{ height: "800px" }} title="We got engaged ..." img1="/images/c_the_ring.jpg" img2="/images/n_the_ring.jpg" type="left">
                            <p>
                                <b>Sai:</b> I made it known that I was going to propose before the end of 2021. I considered popping the
                                question when Ashley went skydiving for her birthday that October. I also considered it during our trip to Chicago and Milwaukee
                                in November. Christmas was considered, too. So, why did I not propose during one of those occasions? Well, the supply chain had
                                different ideas and, thus, New Year's Eve it was.
                            </p>
                            <p>
                                <b>Ash:</b> True to form, Sai waited until the very last minute ... well, almost. He planned to propose as the clock struck midnight
                                to 2022 but when he put a wrapped gift under the Christmas tree around 9 p.m., me - true to form - pestered him about opening it
                                before heading out to celebrate New Year's Eve with friends. Inside was a Snitch-shaped music box Sai had crafted to
                                be a ring box. It played "Hedwig's Theme," of course. Right before opening it, a note read: "I open at the close." Me being the
                                huge Harry Potter nerd that I am, it was absolutely perfect.
                            </p>
                        </Section>

                        <Section style={{ height: "800px" }} title="... and bought a house." img1="/images/c_full_house.jpg" img2="/images/n_full_house.jpg" type="right">
                            <p>
                                <b>Ash:</b> We had agreed buying a house was the biggest priority for us in 2022 (even before planning two weddings!). House hunting in the
                                pandemic-frenzied market, in a fast-growing city like Charlotte, was an adventure and a true test of our relationship. We had to
                                compromise for what the other person wanted, crunched numbers endlessly, had a lot of questions and conversations, and fended off
                                investors and all-cash buyers. The real estate universe worked its magic and we found a new construction townhouse in a great
                                neighborhood only a few weeks after starting our search. We closed on March 11, 2022, and couldn't be happier to be homeowners.
                            </p>
                        </Section>

                        <Section style={{ height: "800px" }} title="Our next big adventure will take one of us home" img1="/images/c_cooking_food.jpg" img2="/images/n_cooking_food.jpg" type="left">
                            <p>
                                <b>Sai:</b> It's been so long since I went to India. My parents are there and most of my extended family is as well.
                                It's been more than four years since I saw them all, back in 2018. Ashley and I really wanted to go in October 2019 but that got delayed, and then the pandemic
                                happened. Here we are in 2023, and the stars finally seem to be aligning.
                            </p>
                            <p>
                                <b>Ash:</b> I've been so excited to travel to India, see Sai's hometown and meet his friends and family for as long as we've
                                been together. The pandemic didn't make this trip come as expeditiously as we wanted, but I can't wait for our first wedding
                                in Hyderabad, India, on Feb. 11.
                            </p>
                        </Section>

                        {/* <h2 className="p-t-100-px">Then we arrive at our event.</h2> */}


                    </Page>

                    <Page id="TLDR" title="The Event" bgStyle="app-bg-grad-wedding-blue">
                        <Section style={{ height: "800px" }} title="We are getting married!" img1="/images/c_venue.jpg" img2="/images/n_venue.jpg" type="right">
                            <p>
                                We hope you'll be able to attend our second wedding in the U.S. on <mark>Saturday, April 29, 2023</mark>, in beautiful <mark>Asheville, N.C.</mark>&nbsp;
                                The ceremony is at <mark>5 pm</mark>, followed by a cocktail hour and a reception with dinner and (lots and lots and lots of) dancing.
                            </p>
                            <Venue className="text-center"></Venue>
                        </Section>

                        <div className="row app-section" id="RSVP">
                            <h1 className="display-1 mt-5 mb-4 app-page-head">RSVP</h1>
                            <p className="lead mb-4">
                                Our RSVP has closed and we have finalized our guest count.
                            </p>
                        </div>
                    </Page>

                    {/* <Page id="RSVP" title="RSVP" bgStyle="app-bg-grad-wedding-blue" >

                    </Page> */}

                    <Page id="Registry" title="Registry" bgStyle="app-bg-grad-wedding-sage" >
                        <p className="lead mb-4">
                            We'd appreciate any gifts to help furnish our new home or contribute to our dream tropical honeymoon.
                        </p>
                        <div>
                            <a href="https://www.honeyfund.com/wedding/AshleyAndSai" className="btn btn-outline-light btn-lg" target="_blank">
                                Click here to go to our registry at Honeyfund.
                            </a>
                        </div>
                    </Page>

                    <Page id="Travel" title="Travel" bgStyle="app-bg-grad-wedding-blue" >
                        <div className="col-xl-7 col-lg-9 col-md-11 col-sm-12 text-start">
                            <Venue showIcon></Venue>
                        </div>

                        <div className="col-xl-7 col-lg-9 col-md-11 col-sm-12  text-start">
                            <div className="">
                                <h1 className="mt-5">
                                    <svg id="i-send" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" class="p-1 mb-2 me-2" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5">
                                        <path d="M2 16 L30 2 16 30 12 20 Z M30 2 L12 20" />
                                    </svg>
                                    Getting into Asheville
                                </h1>
                                <div>
                                    <p>
                                        There is a regional airport in Asheville but more routes and carriers can be found at a major airport like Charlotte Douglas
                                        International Airport, which is about a two-hour drive from Asheville. While downtown Asheville is walkable, renting a car
                                        if you're flying into town is recommended to get around efficiently. Uber and Lyft exist but can be hit or miss. Our venue
                                        is in Asheville's historic Montford neighborhood, 2 miles outside of downtown.
                                    </p>
                                    <p>There are three nearby airports:</p>
                                    <ul>
                                        <li><b>AVL</b> (Asheville Regional Airport, 15 miles from venue)</li>
                                        <li><b>GSP</b> (Greenville-Spartanburg International Airport, 78 miles from venue)</li>
                                        <li><b>CLT</b> (Charlotte Douglas International Airport, 118 miles from venue)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-9 col-md-11 col-sm-12  text-start">
                            <div className="">
                                <h1 className="mt-5">
                                    <svg id="i-home" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" class="p-1 mb-2 me-2" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.5">
                                        <path d="M12 20 L12 30 4 30 4 12 16 2 28 12 28 30 20 30 20 20 Z" />
                                    </svg>
                                    Where to stay
                                </h1>
                                <div>
                                    <p>
                                        We have reserved a limited number of discounted rooms at two hotels near Homewood for April 28 and April 29. They are:
                                    </p>
                                    <div className="card text-bg-light my-3">
                                        <div className="card-header fw-bold ">
                                            Crowne Plaza Asheville
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text">1 Resort Drive, Asheville, NC 28806 (2.5 miles from venue)</p>
                                            <p className="card-text">Book by March 31 to receive the discounted rate of $167 per night for a room with two queen beds or $187 per night for a room with one king bed</p>
                                            <p >
                                                <a className="btn btn-outline-primary btn-md" target="_blank" href="https://www.ihg.com/crowneplaza/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qIta=99801505&icdv=99801505&qSlH=avlss&qCiD=28&qCiMy=032023&qCoD=30&qCoMy=032023&qGrpCd=FJW&qAAR=6CBARC&qRtP=6CBARC&setPMCookies=true&qSHBrC=CP&qDest=1%20Resort%20Drive,%20Asheville,%20NC,%20US&srb_u=1">
                                                    Reservation link
                                                </a>
                                            </p>
                                            <p>Or call the hotel's toll-free number at 844-330-0296; mention "Fahey Jonnala" Wedding to receive the special group rate.</p>
                                        </div>
                                    </div>

                                    <div className="card text-bg-light my-3">
                                        <div className="card-header fw-bold ">
                                            Courtyard by Marriott Asheville Biltmore Village
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text">26 Meadow Road, Asheville, NC 28803 (3.4 miles from venue)</p>
                                            <p className="card-text">Book by March 17 to receive the discounted rate of $229 per night</p>
                                            <p >
                                                <a className="btn btn-outline-primary btn-md" target="_blank" href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1663788017034&key=GRP&app=resvlink">
                                                    Reservation link
                                                </a>
                                            </p>
                                        </div>
                                    </div>

                                    <p className="my-3">
                                        There are many hotels, Airbnbs and Vrbos in the area that can accommodate a wide range of budgets and preferences. Let us know if you'd like a recommendation for an area to stay in!
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-9 col-md-11 col-sm-12  text-start">
                            <div className="">
                                <h1 className="mt-5">
                                    <svg id="i-location" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" class="p-1 mb-2 me-2" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5">
                                        <circle cx="16" cy="11" r="4" fill="white" />
                                        <path d="M24 15 C21 22 16 30 16 30 16 30 11 22 8 15 5 8 10 2 16 2 22 2 27 8 24 15 Z" />
                                    </svg>
                                    Things to do in Asheville
                                </h1>
                                <div>
                                    <p>
                                        There's something for everyone in Asheville, but here are a few of our favorite spots in town or highly recommended by friends.
                                    </p>

                                    <div class="card p-2 text-bg-light">
                                        <nav class="mb-4 text-bg-light">
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button class="nav-link fw-semibold active" id="nav-Hiking-tab" data-bs-toggle="tab" data-bs-target="#nav-Hiking" type="button" role="tab" aria-controls="nav-Hiking" aria-selected="true">Hiking</button>
                                                <button class="nav-link fw-semibold" id="nav-Food-tab" data-bs-toggle="tab" data-bs-target="#nav-Food" type="button" role="tab" aria-controls="nav-Food" aria-selected="false">Food</button>
                                                <button class="nav-link fw-semibold" id="nav-Libations-tab" data-bs-toggle="tab" data-bs-target="#nav-Libations" type="button" role="tab" aria-controls="nav-Libations" aria-selected="false">Libations</button>
                                                <button class="nav-link fw-semibold" id="nav-Explore-tab" data-bs-toggle="tab" data-bs-target="#nav-Explore" type="button" role="tab" aria-controls="nav-Explore" aria-selected="false">Points of interest</button>
                                            </div>
                                        </nav>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-Hiking" role="tabpanel" aria-labelledby="nav-Hiking-tab" tabindex="0">
                                                <ul>
                                                    <li><b>Mount Mitchell State Park:</b> Said to be the highest point east of the Mississippi River, Mount Mitchell State Park has several trails, including a very short pinnacle hike with panoramic views. It's at the top of the Blue Ridge Parkway, a stunning drive especially on a clear spring day.</li>
                                                    <li><b>Graveyard Fields:</b> The trail is about 3.3 miles round trip, also along the Blue Ridge Parkway and very popular. The waterfalls here are awesome.</li>
                                                    <li><b>Sam Knob at Black Balsam Trail:</b> Also off the Blue Ridge Parkway, the trail is moderately difficult and starts in a beautiful wildflower meadow, before a relatively short hike to stunning mountain views.</li>
                                                    <li><b>Catawba Falls:</b> Although not at a high elevation, this short, relatively easy hike includes several waterfalls, including the showstopper 100-foot tall waterfall at the end. It can get crowded but it's worth it for the views!</li>
                                                </ul>
                                            </div>
                                            <div class="tab-pane fade" id="nav-Food" role="tabpanel" aria-labelledby="nav-Food-tab" tabindex="0">
                                                <ul>
                                                    <li><b>Luella's Bar-B-Que</b> (barbecue, including vegetarian barbecue)</li>
                                                    <li><b>Chai Pani</b> (James Beard Award-winning modern Indian)</li>
                                                    <li><b>Mehfil</b> (Indian cuisine, 2022 Best of Asheville winner)</li>
                                                    <li><b>White Duck Taco</b> (can accommodate a wide range of diets)</li>
                                                    <li><b>Hemingway's</b> (Cuban cuisine, delicious cocktails, great sunset views from the patio)</li>
                                                    <li><b>Biscuit Head</b> (breakfast/brunch spot, get in line early)</li>
                                                    <li><b>All Day Darling</b> (breakfast/brunch/lunch, Montford location is down the street from Homewood)</li>
                                                    <li><b>The S&W Market</b> (food hall)</li>
                                                    <li><b>French Broad Chocolate Lounge</b> (touristy but delicious)</li>
                                                    <li>We're not fancy or big on eating meat but here are a couple of other restaurants we haven't been to that are said to be great: Curate, Posana, Rhubarb, 12 Bones Smokehouse</li>
                                                </ul>
                                            </div>
                                            <div class="tab-pane fade" id="nav-Libations" role="tabpanel" aria-labelledby="nav-Libations-tab" tabindex="0">
                                                <ul>
                                                    <li><b>New Belgium Brewing</b></li>
                                                    <li><b>Sierra Nevada</b></li>
                                                    <li><b>Highland Brewing</b></li>
                                                    <li><b>Hi-Wire Brewing</b></li>
                                                    <li><b>Wedge Brewing Co.</b></li>
                                                    <li><b>Burial Beer Co.</b></li>
                                                    <li><b>Marked Tree</b> (wine bar is in downtown Asheville; the vineyard is about 40 minutes from Asheville in Flat Rock and worth the drive if you have a couple of spare hours)</li>
                                                    <li><b>Storm Rhum Bar</b> (cocktails)</li>
                                                    <li><b>Capella on 9</b> (ninth-floor bar at the AC Hotel downtown)</li>
                                                    <li><b>Battery Park Book Exchange</b> (champagne bar in a bookstore at Grove Arcade)</li>
                                                </ul>
                                            </div>
                                            <div class="tab-pane fade" id="nav-Explore" role="tabpanel" aria-labelledby="nav-Explore-tab" tabindex="0">
                                                <ul>
                                                    <li><b>Biltmore Estate</b></li>
                                                    <li><b>River Arts District</b></li>
                                                    <li><b>North Carolina Arboretum</b></li>
                                                    <li><b>Asheville Pinball Museum</b></li>
                                                    <li><b>Orange Peel</b> (concert venue)</li>
                                                    <li>Nearby towns of interest: Hendersonville, Black Mountain, Weaverville, Lake Lure</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </Page>


                    <Page id="Contact" title="Contact" bgStyle="app-bg-grad-wedding-sage" >
                        <p className="lead mb-4">
                            Email <b>Ashley</b> at <mark>atfahey91@gmail.com</mark> or <b>Sai</b> at <mark>saiphaninderreddy@gmail.com</mark> for any questions, concerns or walking-down-the-aisle advice!
                        </p>
                    </Page>


                </div>
            </>
        );
    }

    componentDidMount() {
        window.setTimeout(() => {
            new window.bootstrap.ScrollSpy(document.body, {
                target: '#topLevelNav',
                smoothScroll: true,
                rootMargin: "0px 0px -25%",
                threshold: [0.1, 0.5, 1],
            });
            console.log("setup the scroll spy again");
        }, 2000);
    }
}


// export default withAuthenticator(App, {hideSignUp: true});
// export default withAuthenticator(App);
export default App;

