import React, { useCallback, useState, useLayoutEffect, useRef, useEffect } from "react";

const useContainerDimensions = () => {
    const [size, setRect] = useState(null);
    const ref = useCallback(node => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, []);
    return [size, ref];
}

export default function Section({ title = "", img1, img2, type = "left", children, style, id }) {

    const content = (
        <div className="col-lg-4 app-section-content-container">
            <div className="text-start">
                <h1 className="mt-5 text-center">{title}</h1>
                {children}
            </div>
        </div>
    );
    const imageContainer = (
        <div className="col app-section-img-container">
            <div className="app-section-img-switcher" >
                <img src={img2} className="app-section-img-1" />
            </div>
        </div>
    );
    style = {};

    // Method 1
    // ------------------------------------------------------------------------
    // break point for <=(lg, 992px) screens
    // const [size, ref] = useContainerDimensions();

    // const ref = useRef(null)
    // useLayoutEffect(() => {
    //     ref.current.width
    // });


    // Method 2
    // ------------------------------------------------------------------------
    // const ref = useRef(null);
    // const [height, setHeight] = useState(0);
    // const [width, setWidth] = useState(0);

    // useLayoutEffect(() => {
    //     setHeight(ref.current.offsetHeight);
    //     setWidth(ref.current.offsetWidth);
    //     // console.log(ref);
    //     console.log(ref.current.parentElement);
    //     console.log(ref.current.parentElement.offsetHeight);
    //     console.log(ref.current.parentElement.offsetWidth);
    // }, []);

    // if (width < 992) {
    //     type = "left";
    // }
    // console.log({width, height});



    // Method 3
    // ------------------------------------------------------------------------
    const [layoutType, setLayoutType] = useState(type);
    function handleResize() {
        if (ref.current.parentElement.offsetWidth < 992) {
            console.log(ref.current.parentElement.offsetWidth);
            setLayoutType("right")
        }
        else {
            setLayoutType(type)
        }
    }

    const ref = useRef(null);
    useLayoutEffect(() => {
        handleResize()
        if (ref.current) {
            window.addEventListener('resize', handleResize);
        }

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [ref]);
    // ------------------------------------------------------------------------

    return (
        <div ref={ref} style={style} className="row app-section" id={id}>
            {layoutType === "left" && content}
            {imageContainer}
            {layoutType === "right" && content}
        </div>
    );
}

