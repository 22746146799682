export default function Venue({ className, showIcon = false }) {
    return (
        <div className={className}>
            <h1 className="mt-5">
                {showIcon && (
                    <svg id="i-star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" class="p-1 mb-2 me-2" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5">
                        <path d="M16 2 L20 12 30 12 22 19 25 30 16 23 7 30 10 19 2 12 12 12 Z" />
                    </svg>
                )}
                Venue
            </h1>
            <div className="text-start">
                <p>The address for our beautiful venue, Homewood, is:</p>
                <p class="lead">
                    19 Zillicoa St. Asheville, NC 28801
                    <a class="btn btn-outline-light btn-sm" target="_blank" href="https://goo.gl/maps/JZJ7zgjXeSQUj1yh7">Directions</a>
                </p>
            </div>
        </div>
    );
}
