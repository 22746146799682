import { defaultDarkModeOverride } from '@aws-amplify/ui-react';

export default {
    name: 'my-theme',
    tokens: {
        fonts: {
            default: {
                variable: { value: '"Noto Sans", sans-serif' },
                static: { value: '"Noto Sans", sans-serif' },
            },
        },
        colors: {
            green: {
                10: {value: '#CDFFE8'},
                20: {value: '#88FFC8'},
                40: {value: '#2BA96F'},
                60: {value: '#198754'},
                80: {value: '#0C653C'},
                90: {value: '#034325'},
                100: {value: '#02301A'},
            },
            option_red: {
                10: {value: '#FFE9EB'},
                20: {value: '#FFC2C8'},
                40: {value: '#FF273A'},
                60: {value: '#E81224'},
                80: {value: '#BF0010'},
                90: {value: '#96000D'},
                100: {value: '#6E0009'},
            },
            brand: {
                primary: {
                    10: { value: '{colors.option_red.10}' },
                    20: { value: '{colors.option_red.20}' },
                    40: { value: '{colors.option_red.40}' },
                    60: { value: '{colors.option_red.60}' },
                    80: { value: '{colors.option_red.80}' },
                    90: { value: '{colors.option_red.90}' },
                    100: { value: '{colors.option_red.100}' },
                },
                secondary: {
                    10: { value: '{colors.option_red.10}' },
                    20: { value: '{colors.option_red.20}' },
                    40: { value: '{colors.option_red.40}' },
                    60: { value: '{colors.option_red.60}' },
                    80: { value: '{colors.option_red.80}' },
                    90: { value: '{colors.option_red.90}' },
                    100: { value: '{colors.option_red.100}' },
                },
            },
            border: {
                primary: { value: '#c0c0c0' },
            },
        },
        shadows: {
            small: {
                value: {
                    offsetX: '2px',
                    offsetY: '2px',
                    blurRadius: '4px',
                    color: '{colors.shadow.tertiary.value}',
                },
            },
            medium: {
                value: {
                    offsetX: '5px',
                    offsetY: '5px',
                    spreadRadius: '2px',
                    blurRadius: '6px',
                    color: '{colors.shadow.secondary.value}',
                },
            },
            large: {
                value: {
                    offsetX: '8px',
                    offsetY: '8px',
                    spreadRadius: '6px',
                    blurRadius: '8px',
                    color: '{colors.shadow.primary.value}',
                },
            },
        },
        components: {
            card: {
                boxShadow: { value: '{shadows.medium.value}' },
            },
            radio: {
                button: {
                    padding: { value: '{borderWidths.small}' },
                    borderWidth: { value: '{borderWidths.small}' },
                },
            },
            heading: {
                1: { fontWeight: { value: '{fontWeights.extrabold.value}' } },
                2: { fontWeight: { value: '{fontWeights.extrabold.value}' } },
                3: { fontWeight: { value: '{fontWeights.extrabold.value}' } },
                4: { fontWeight: { value: '{fontWeights.extrabold.value}' } },
                5: { fontWeight: { value: '{fontWeights.extrabold.value}' } },
                6: { fontWeight: { value: '{fontWeights.extrabold.value}' } },
            },
            button: {
                primary: {
                    backgroundColor: { value: '{colors.brand.primary.60.value}' },
                    color: { value: '#f0f0f0' },
                    borderColor: { value: '{colors.border.primary.value}' },
                },
            },
        },
        radii: {
            small: { value: '0' },
            medium: { value: '0' },
            large: { value: '0' },
        },
        space: {
            small: { value: '1rem' },
            medium: { value: '1.5rem' },
            large: { value: '2rem' },
        },
        borderWidths: {
            small: { value: '1px' },
            medium: { value: '2px' },
            large: { value: '5px' },
        },
    },
    overrides: [defaultDarkModeOverride],
};
